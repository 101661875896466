import React, { PropsWithChildren, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { ifProp } from 'styled-tools';

import { Loader as LoaderOrigin } from '../Loader';
import { ButtonIcon } from '../ButtonIcon';
import { Icon } from '../Icon';
import { css } from 'styled-components';

interface Props {
  loading?: boolean;
  fullHeight?: boolean;
}

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export function PageWrapper(props: PropsWithChildren<Props>) {
  const { loading, children, fullHeight } = props;
  const [toTopVisible, setToTopVisible] = useState(false);

  const onScroll = () => {
    setToTopVisible(document.documentElement.scrollTop > 400);
  };

  useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <Wrapper $fullHeight={fullHeight}>
      {children}
      {loading ? <Loader /> : null}
      <ScrollToTop visible={toTopVisible}>
        <ButtonIcon onClick={scrollToTop} palette="tertiary">
          <Icon name="chevron" />
        </ButtonIcon>
      </ScrollToTop>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ $fullHeight?: boolean }>`
  margin: 0 auto;
  max-width: 1112px;
  padding: 82px 16px;

  ${ifProp(
    '$fullHeight',
    css`
      min-height: calc(100vh - 5rem);
      display: grid;
    `,
  )};

  @media print {
    padding: 0;
  }
`;

const Loader = styled(LoaderOrigin)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
`;

const ScrollToTop = styled.div<{ visible: boolean }>`
  position: fixed;
  bottom: 1rem;
  left: 50%;
  margin-left: calc(540px + 1rem);
  opacity: ${ifProp('visible', 1, 0)};
  transition: opacity 0.2s;

  svg {
    transform: rotate(180deg);
  }
`;
