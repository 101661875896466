import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import FontFaceObserver from 'fontfaceobserver';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { GlobalStyle } from 'styles/global-styles';

import { RoutesList } from './routes';
import { AuthContext } from '../context/AuthContext';
import { selectLoaded } from './slice/selectors';
import { useDictSlice } from './slice';
import { GoogleTag } from './google-tag';
import { CustomCoach } from './components/CustomCoach/Loadable';
import { Welcome } from './components/Welcome/Loadable';
import { useProfileSlice } from './pages/SettingsPage/Profile/slice';
import { useReportSectionsSlice } from './components/Entry/Report/slice';

const fontObserver = new FontFaceObserver('Source Sans Pro', {});
const tagId = process.env.GTM_TAG;

fontObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

export function App() {
  const { i18n } = useTranslation();
  const { isAuthenticated, user } = useContext(AuthContext);
  const loaded = useSelector(selectLoaded);
  const dispatch = useDispatch();
  const { actions } = useDictSlice();
  const { actions: profileActions } = useProfileSlice();
  const { actions: reportSectionActions } = useReportSectionsSlice();

  useEffect(() => {
    if (isAuthenticated && !loaded) {
      dispatch(actions.getDict());
      dispatch(profileActions.getUser());
      dispatch(reportSectionActions.getReportSections());
    }
  }, [isAuthenticated]);

  return (
    <>
      <Helmet
        titleTemplate="%s"
        defaultTitle="COACHINSIDE"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="" />
      </Helmet>
      <RoutesList />
      <GlobalStyle />
      <GoogleTag tagId={tagId} />
      <CustomCoach />
      {isAuthenticated && !user?.lastloggedin ? <Welcome /> : null}
    </>
  );
}
