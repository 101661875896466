import React from 'react';
import { Col as ColOrigin, Row as RowOrigin } from './Origin';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { prop } from '../../../styles/tools';

interface RowProps {
  gutterY?: number;
  gutterX?: number;
  gutter?: any;
  display?: 'flex' | 'inline-flex';
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column‑reverse';
  flexWrap?: 'nowrap' | 'wrap' | 'wrap‑reverse';
  justifyContent?:
    | 'flex‑start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space‑around';
  alignItems?:
    | 'baseline'
    | 'first baseline'
    | 'last baseline'
    | 'flex‑start'
    | 'flex-end'
    | 'center'
    | 'stretch';
  alignContent?:
    | 'baseline'
    | 'first baseline'
    | 'last baseline'
    | 'flex‑start'
    | 'flex-end'
    | 'center'
    | 'space‑between'
    | 'space-around'
    | 'stretch';
  elementType?: 'div' | 'span';
  children: any;
  height?: number | string;
  style?: React.CSSProperties;
}

export const Col = styled(({ gutterY, gutterX = 1, ...rest }) => (
  <ColOrigin gutter={gutterX} {...rest} />
))<{ gutterY?: number }>`
  ${ifProp(
    'gutterY',
    css`
      margin-top: ${prop('gutterY')}rem !important;
    `,
  )}
  min-width: 0;
`;

export const Row = styled(({ gutterY, gutterX = 1, ...rest }: RowProps) => (
  <RowOrigin gutter={gutterX} {...rest} />
))`
  ${props => {
    if (props.gutterY) {
      return css`
        margin-top: -${ifProp('gutterY', prop('gutterY'), 0)}rem;

        & > ${Col} {
          margin-top: ${ifProp('gutterY', prop('gutterY'), 0)}rem;
        }
      `;
    }
  }}
  ${ifProp(
    'gutterY',
    css`
      margin-top: -${ifProp('gutterY', prop('gutterY'), 0)}rem;

      & > ${Col} {
        margin-top: ${ifProp('gutterY', prop('gutterY'), 0)}rem;
      }
    `,
  )}
  height: ${prop => prop.height};
`;
