import React from 'react';
import { Switch } from 'react-router-dom';

import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

import { HomePage } from '../pages/HomePage/Loadable';
import { NotFoundPage } from '../pages/NotFoundPage/Loadable';
import { CoachesPage } from '../pages/CoachesPage/Loadable';
import { AuthPage } from '../pages/AuthPage/Loadable';
import { SettingsPage } from '../pages/SettingsPage/Loadable';
import { CoachPage } from '../pages/CoachPage/Loadable';
import { CollectionsPage } from '../pages/CollectionsPage/Loadable';
import { CollectionPage } from '../pages/CollectionPage/Loadable';
import { ShortlistsPage } from '../pages/ShortlistsPage/Loadable';
import { ShortlistPage } from '../pages/ShortlistPage/Loadable';
import { NewsArticlePage } from '../pages/NewsArticlePage/Loadable';
import { NewsPage } from '../pages/NewsPage/Loadable';
import { AdminPage } from '../pages/AdminPage/Loadable';
import { CreateCollectionPage } from '../pages/CreateCollectionPage/Loadable';
import { ManageArticlesPage } from '../pages/ManageArticlesPage/Loadable';
import { FaqPage } from '../pages/FaqPage/Loadable';
import { ComparePage } from '../pages/ComparePage/Loadable';
import { TrainerTickerPage } from '../pages/TrainerTickerPage/Loadable';
import { DebutsPage } from '../pages/DebutsPage/Loadable';
import { TeamPage } from '../pages/TeamPage/Loadable';
import { IndexPage } from '../pages/IndexPage/Loadable';
import { CoachProfilePage } from '../pages/CoachProfilePage/Loadable';
import { AccessManagementPage } from '../pages/AccessManagementPage/Loadable';
import { PlayerPage } from '../pages/PlayerPage/Loadable';
import { PlayerRelationPage } from '../pages/PlayerRelationPage/Loadable';
import { ReportConstructorPage } from '../pages/ReportConstructorPage/Loadable';

export const RoutesList = () => (
  <Switch>
    <PrivateRoute exact path="/player/:id" component={PlayerPage} />
    <PrivateRoute
      exact
      path="/coaches"
      permissions={['access_coaches']}
      component={CoachesPage}
    />
    <PrivateRoute
      exact
      path="/coach"
      permissions={['access_coaches']}
      component={CoachPage}
    />
    <PrivateRoute
      exact
      path="/coach/:id"
      permissions={['access_coaches']}
      component={CoachPage}
    />
    <PrivateRoute
      exact
      path="/collections"
      permissions={['access_collections']}
      component={CollectionsPage}
    />
    <PrivateRoute
      exact
      path="/collection/:id"
      permissions={['access_collections']}
      component={CollectionPage}
    />
    <PrivateRoute
      exact
      path="/create-collection"
      tags={['admin']}
      component={CreateCollectionPage}
    />
    <PrivateRoute exact path="/settings" component={SettingsPage} />
    <PrivateRoute
      exact
      path="/shortlists"
      permissions={['access_shortlists']}
      component={ShortlistsPage}
    />
    <PrivateRoute
      exact
      path="/shortlist/:id"
      permissions={['access_shortlists']}
      component={ShortlistPage}
    />
    <PrivateRoute
      exact
      path="/news"
      permissions={['access_news']}
      component={NewsPage}
    />
    <PrivateRoute
      exact
      path="/news/:id"
      permissions={['access_news']}
      component={NewsArticlePage}
    />
    <PrivateRoute path="/admin" tags={['admin']} component={AdminPage} />
    <PrivateRoute
      path="/manage-articles"
      permissions={['manage_articles']}
      component={ManageArticlesPage}
    />
    <PrivateRoute path="/faq" component={FaqPage} />

    <PrivateRoute
      exact
      path="/trainer-ticker"
      tags={['!coach']}
      component={TrainerTickerPage}
    />
    <PrivateRoute
      exact
      path="/debuts"
      tags={['!coach']}
      component={DebutsPage}
    />

    <PrivateRoute
      path={[
        '/compare/:first/:second/:third',
        '/compare/:first/:second',
        '/compare/:first',
        '/compare',
      ]}
      component={ComparePage}
      tags={['!coach']}
      exact
    />

    <PrivateRoute
      exact
      path="/team/:id"
      component={TeamPage}
      tags={['!coach']}
    />

    <PrivateRoute exact path="/home" component={HomePage} tags={['!coach']} />

    <PrivateRoute
      exact
      path="/profile"
      component={CoachProfilePage}
      tags={['coach']}
    />

    <PrivateRoute
      exact
      path="/access-management"
      component={AccessManagementPage}
      tags={['coach']}
    />

    <PrivateRoute
      exact
      path={['/player-relation', '/player-relation/:playerId']}
      component={PlayerRelationPage}
    />

    <PrivateRoute
      tags={['client-admin', 'admin']}
      exact
      path="/report-constructor"
      component={ReportConstructorPage}
    />

    <PrivateRoute exact path="/" component={IndexPage} />

    <PublicRoute path="/auth" component={AuthPage} />

    <PublicRoute component={NotFoundPage} />
  </Switch>
);
